var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"bBmdI7k-4XAxiOOU3-0r8"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {
    Debug as DebugIntegration,
    ExtraErrorData as ExtraErrorDataIntegration
} from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
import { Environment } from 'src/constants/environments';

const isDev = process.env.NODE_ENV === Environment.DEV;
const dataDepth = 10;

Sentry.init({
    dsn: getConfig().publicRuntimeConfig.sentryDsn,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.01,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    normalizeDepth: dataDepth + 1,
    // beforeSend: isDev ? () => null : undefined,
    integrations: [
        new ExtraErrorDataIntegration({
            // limit of how deep the object serializer should go. Anything deeper than limit will
            // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
            // a primitive value. Defaults to 3.
            depth: dataDepth,
        }),
        ...(isDev ? [ new DebugIntegration() ] : []),
    ],
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
    ],
});
